
import {css} from '@emotion/react'
import {SelectSuffixIcon} from '@components/atoms/Select/SelectSuffixIcon'
import { FieldPath, UseFormRegister} from 'react-hook-form'
import {selectTagStyle} from '@/styles/global'


const selectStyles = css({
    width: '100%',
    height: '56px',
    background: '#FFFFFF',
    border: '1px solid #EAEBED',
    boxSizing: 'border-box',
    padding: '12px',
    fontSize: '16px',
    borderRadius: '4px',
    '@media (max-width: 768px)': {
        height: '45px',
    },
})

const initialOptionText = '選択してください'

export type SelectOptionValue = {
    value: string
    name: string
}

export const Select = <TFieldValues extends Record<string, any> , TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(props: {
    register: UseFormRegister<TFieldValues>,
    name:TFieldName
    options: SelectOptionValue[]
    onChange?: ()=>void
}) => {
    const { register, name, options, onChange } = props

    return (
        <SelectSuffixIcon>
            <select css={[selectTagStyle, selectStyles]} {...register(name, {
                required: '入力してください。',
                onChange: ()=>{onChange?.()},
                validate:(value)=> value === initialOptionText ? '入力してください。' : true
            })} >
                <option hidden key='hidden'>{initialOptionText}</option>
                {options.map(option => <option value={option.value} key={option.value}>{option.name}</option>)}
            </select>
        </SelectSuffixIcon>
    )
}
