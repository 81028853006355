import { UserAddress } from '@/network/api/reservationConfirmation'
import { ApiInfo, apiInfoFunc } from '../apiInfo'


export type OrderConfirmation = {
    orderConfirmation: {
        itemCode: string
        couponCode: string | undefined
        isSubscribeMailmagazine: boolean,
        shipAddress: UserAddress,
        billAddress: UserAddress
        planName: 'light' | 'standard' |'premium'
        mzdaoCode: string | undefined
    }
}

export type OrderConfirmationResult = {
    totalAmount: number
    itemPrice: number
    discountPrice: number
}

const apiInfoPostOrderConfirmation: ApiInfo<OrderConfirmationResult, undefined, OrderConfirmation> = {
    method: 'POST',
    url: () => '/web_ec_v2/tf/order_confirmation',
    response: {} as OrderConfirmationResult,
    query: undefined,
    requestBody: {} as OrderConfirmation
}

export const usePostOrderConfirmation = apiInfoFunc.toUseRequest(apiInfoPostOrderConfirmation)