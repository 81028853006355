import React from 'react'
import {css} from '@emotion/react'
import {fonts} from '@/styles/fonts'


const containerStyles = css({
    fontSize: fonts.size.fontMobile12Pc16,
    marginBottom: 10,
})
const spanStyles = css({
    fontWeight: 'bold',
    color: '#DC3251',
})

/**
 * 「 * は必須項目です 」 のテキストを表示させるだけのコンポーネント
 * @constructor
 */
export const RequiredExplainText: React.FC = () => (
    <p css={containerStyles}>
        <span css={spanStyles}>
            *
        </span>{' '}
        は必須項目です。
    </p>
)
