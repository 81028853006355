import { useState} from 'react'
// Stripe.js
// https://stripe.com/docs/stripe-js/react
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { StripeError } from '@stripe/stripe-js'
import { TokenResult } from '@stripe/stripe-js/types/stripe-js/stripe'
import {CreatedCreditCard} from '@/network/api/stripePublicKey'



type CreateCreditCardResult = {
        data?: CreatedCreditCard | undefined
        customError?:StripeCustomError | undefined
    }
type CreditCardHook = {
    /* 失敗するとundefinedが返される。 */
    createCreditCard: () => Promise<CreateCreditCardResult>
    /* 失敗すると値が入る。 */
    stripeError?:StripeError |StripeCustomError
    /* 前回作成されたクレジットカード返す。前回失敗しているとundefinedが返される */
    creditCard?:CreatedCreditCard
}
/**
 * クレジットカードを作成する
 * 注意: Elementsの内部でないと使えないため、<CreditCardFormContainer>でラップされたコンポーネント内で使う必要がある
 */
export const useCreateCreditCard = (): CreditCardHook => {

    const stripe = useStripe()
    const elements = useElements()
    const [error, setError] = useState<StripeError | StripeCustomError | undefined>()
    const [createdCreditCard, setCreatedCreditCard] = useState<CreatedCreditCard | undefined>()
    /**
     * クレジットカードを作成する
     * 作成に成功したらクレジットカードの情報を返す
     * 失敗したらundefinedを返して、エラーメッセージをセットする
     * ネットワークなどのカスタムエラーの可能性があるのは、別処理をしている
     */
    const createCreditCard = async (): Promise<CreateCreditCardResult>  => {
        if (!stripe || !elements || !elements.getElement(CardElement)) {
            setError({
                type: 'authentication_error',
                message: 'Formが有効ではありません'
            })
            setCreatedCreditCard(undefined)
            return {}
        }
        const cardElement = elements.getElement(CardElement)
        try {
            const results = await Promise.race([
                await stripe.createToken(cardElement!),
                new Promise((_, reject) =>
                {setTimeout(() => {
                    reject(new Error('networkErr'))
                }, 10000)}
                ),
            ]) as TokenResult // 正しく実行されると、TokenResultが返ってくる

            if (results.token && results.token.card) {
                setError(undefined)
                const creditCard = {
                    token: results.token.id,
                    exp_month: results.token.card.exp_month,
                    exp_year: results.token.card.exp_year,
                    last4: results.token.card.last4
                }
                setCreatedCreditCard(creditCard)
                return {
                    data: creditCard
                }
            }
            setError(results.error)
            setCreatedCreditCard(undefined)
            return {}
        }catch (e: any) {
            if(e?.message === 'networkErr'){
                const newError = {
                    code: 'networkErr',
                    message: 'ネットワークが不安定です'
                } as const
                setError(newError)
                setCreatedCreditCard(undefined)
                return {customError: newError }
            }
            const newError = {
                code: 'unknown',
                message: '予期しないエラーが発生しました'
            } as const
            setError(newError)
            setCreatedCreditCard(undefined)
            return {customError: newError}
        }
    }

    return {
        createCreditCard,
        stripeError: error,
        creditCard: createdCreditCard
    }
}
