import React, { useMemo } from 'react'
import Modal from 'react-modal'
import { css } from '@emotion/react'
import { ReactComponent as CloseIcon } from '../../assets/img/close_icon.svg'
import {buttonTagStyle} from '@/styles/global'

Modal.setAppElement('#root')

const AppModal: React.FC<{
    maxHeight?: string
    maxWidth?: string
    close: () => void
    isOpen: boolean
    children: React.ReactNode
}> = (props) => {
    const { isOpen, close, children, maxWidth, maxHeight } = props

    const modalStyle = useMemo(
        () => ({
            overlay: {
                top: 0,
                left: 0,
                backgroundColor: 'rgba(0,0,0,0.6)',
                zIndex:10000,
            },
            content: {
                // overflowY: 'auto',
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#FFFFFF',
                overflow: 'hidden',
                borderRadius: '10px',
                padding: '30px 16px 16px 16px',
                maxWidth: maxWidth || '400px',
                maxHeight: maxHeight || '400px',
                margin: 'auto',
                width: 'calc(90% - 32px)',
                height: 'calc(100vh - 80px)',
                // boxSizing: 'border-box',
                zIndex:10001,

                // '@media (max-width: 480px)': {
                //     padding: '30px 24px',
                // }
            },
        }),
        // スタイルに関するものなので、初回のみ
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const closeModal = () => {
        close()
    }

    // モーダルを開いているときに、親がスクロールしないようにする
    // https://github.com/reactjs/react-modal/issues/191#issuecomment-393669563
    const disableScroll = () => {
        document.body.style.overflow = 'hidden'
    }
    const ableScroll = () => {
        document.body.style.overflow = 'visible'
    }

    return (
        <Modal
            isOpen={isOpen}
            style={modalStyle}
            onRequestClose={closeModal}
            onAfterOpen={disableScroll}
            onAfterClose={ableScroll}
        >
            <div
                css={css({
                    display: 'flex',
                    justifyContent: 'flex-end',
                })}
            >
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <button
                    type="button"
                    css={[buttonTagStyle, css({
                        background: 'transparent',
                        border: 'transparent',
                        position: 'absolute',
                        top: 10,
                        right: 6,
                    })]}

                    onClick={closeModal}
                >
                    <CloseIcon />
                </button>
            </div>
            {children}
        </Modal>
    )
}

export default AppModal
