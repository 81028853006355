import React from 'react'
import { css } from '@emotion/react'

const containerStyle = css({
    margin: '24px 0 8px 0',
    padding: '0 0 0 0.6em',
    color: '#333',
    borderLeft: '4px solid #333',
    fontWeight: '600',
    lineHeight: 1,
})

export const ConfirmBlackTitle: React.FC<{
    children: React.ReactNode
}> = (props) => {
    const { children } = props

    return (
        <p css={containerStyle}>
            {children}
        </p>
    )
}
