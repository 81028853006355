import {useCallback, useState} from 'react'
import {fetchSignInMethodsForEmail, signInWithEmailAndPassword, UserCredential} from 'firebase/auth'
import {isValidEmail} from '@/utils/validator/emailValidator'
import {firebaseAuth} from '@/network/firebase/firebaseAuth'


export type EmailAuthMethodStatus = 'newUser' | 'authByPassword' | 'authBySns' | 'invalidEmail' | undefined
export type EmailLoginStatus = 'newUser' | 'loginSuccess' |  'loginFailure' | 'networkErr'

/**
 * FirebaseAuthenticationに、メールアドレスの状態を確認するhooks
 */
export const useFirebaseAuthEmailStatus = () => {
    const [authMethodStatus, setAuthMethodStatus] = useState<EmailAuthMethodStatus>()
    /**
     * 既存ユーザでログインが成功したときのみ存在する
     */

    const checkEmailAuthStatus = useCallback(async (email?: string): Promise<string> => {
        if( !email || !isValidEmail(email) ){
            setAuthMethodStatus('invalidEmail')
            return Promise.resolve('invalidEmail')
        }
        try {
            const authMethods = await Promise.race([
                fetchSignInMethodsForEmail( firebaseAuth, email),
                new Promise((_, reject) =>
                {setTimeout(() => {
                    reject(new Error('networkErr'))
                }, 10000)}
                ),
            ]) as string[] // 正しく実行されると、string[]が返ってくる

            if(authMethods.length > 0){
                if(authMethods.includes('password')){
                    setAuthMethodStatus('authByPassword')
                    return 'authByPassword'
                }
                setAuthMethodStatus('authBySns')
                return 'authBySns'

            }
            setAuthMethodStatus('newUser')
            return 'newUser'

        }catch (e: any) {
            if(e.message === 'networkErr'){

                return 'networkErr'
            }
            return 'invalidEmail'
        }

    },[setAuthMethodStatus])

    /**
     * 新規ユーザならnewUser
     * 認証済みのユーザなら、 パスワード認証できるかチェックしてログインできるか
     * 認証済みのユーザなら、パスワード認証できなければ
     */
    const checkLoginBeforeOrderConfirm = useCallback(async (email: string, password:string): Promise<{
        status: EmailLoginStatus,
         /**
          * 既存ユーザでログインが成功したときのみ存在する
          * */
        userIdToken?: string
    }> => {
        // authMethodStatusがundefinedのときは、checkEmailAuthStatusが一度も実行されていない状態なので、少なくとも一度は実行する
        const currentAuthMethodStatus = authMethodStatus || await checkEmailAuthStatus(email)
        if(currentAuthMethodStatus === 'newUser'){
            return { status: 'newUser' }
        }

        if(currentAuthMethodStatus === 'authByPassword' || currentAuthMethodStatus === 'authBySns'){
            try {
                const result = await Promise.race([ 
                    signInWithEmailAndPassword(firebaseAuth, email, password),
                    new Promise((_, reject) =>
                    {setTimeout(() => {
                        reject(new Error('networkErr'))
                    }, 10000)}
                    ),
                ])  as UserCredential

                const userIdToken = await result.user.getIdToken()

                return {
                    status: 'loginSuccess',
                    userIdToken
                }
            }catch (e: any) {
                if(e.message === 'networkErr'){
                    return { status: 'networkErr' }
                }
                return { status: 'loginFailure' }
            }
        }
        return { status: 'loginFailure' }
    },[authMethodStatus, checkEmailAuthStatus])


    return {
        authMethodStatus,
        checkEmailAuthStatus,
        checkLoginBeforeOrderConfirm,
    }
}
