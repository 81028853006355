import React, { useMemo, useState } from 'react'
import {PurchaseFormPage} from '@components/pages/PurchaseFormPage/PurchaseFormPage'
import {PurchaseThanksPage} from '@components/pages/PurchaseThanksPage'
import {css, Global} from '@emotion/react'
import {ErrorModal} from '@components/organisms/ErrorModal'
import { CreditCardFormContainer } from '@components/atoms/CreditCardForm/CreditCardFormContainer'
import {ErrResult} from './network/fetcher'
import {isStaging} from '@/configure/env'
import {globalStyle} from '@/styles/global'
import {useHtmlDataset} from '@/configure/htmlDataset'
import { FormContext, ValidConfirmationResult } from '@/store/formContext'

const App:React.FC = () => {
    const [page, setPage] = useState<Page>('form')
    const [errResult, setErrResult] = useState<ErrResult|undefined>(undefined)
    const htmlDataset = useHtmlDataset()
    const [validConfirmationResult, setValidConfirmationResult] = useState<ValidConfirmationResult>()
    const orderContextValue = useMemo(() => ({
        htmlDataset,
        validConfirmationResult,
        setValidConfirmationResult
    }), [validConfirmationResult, htmlDataset])

    
    return (
        <div>
            <Global styles={globalStyle} />
            {errResult && <ErrorModal errResult={errResult} setErrResult={setErrResult}/> }
            {isStaging && <div css={css({textAlign: 'center', background: '#ffc53d', color:'white'})}>ステージング環境</div>}
            {page === 'form' && (
                <FormContext.Provider value={orderContextValue}>
                    <CreditCardFormContainer>
                        <PurchaseFormPage itemCode={htmlDataset.itemCode} setPage={setPage} setErrResult={setErrResult}/>
                    </CreditCardFormContainer>
                </FormContext.Provider>
            )}
            {page === 'thanks' && (
                <PurchaseThanksPage deliveryDate={htmlDataset.deliveryDate} />
            )}
        </div>
    )
}

export default App
