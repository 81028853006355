import React, {useRef} from 'react'
import {css} from '@emotion/react'
import {Checkbox} from '@components/atoms/CheckBox/Checkbox'
import {fonts} from '@/styles/fonts'
import {createUuid} from '@/utils/uuid'
import {inputTagStyle} from '@/styles/global'

const buttonStyles = css({
    maxWidth: '500px',
    width: '100%',
    height: '60px',
    background: '#E3E3E3',
    borderRadius: 4,
})
const textStyles = css({
    fontSize: fonts.size.fontMobile14Pc16,
    userSelect: 'none',
})
const labelStyle = css({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
})

/**
 * ボタンの中にあるチェックボックスとテキスト
 * @param props
 * @constructor
 */
export const CheckboxButton: React.FC<{
    setIsChecked: (isChecked: boolean) => void,
    isChecked:boolean
    label: string
} > = (props) => {
    const { label, isChecked, setIsChecked } = props
    // labelとinputで共有することで、チェックボックス以外をクリックしてもチェックが入る
    const checkBoxUuid = useRef(createUuid())

    return (
        <div css={buttonStyles}>
            <label htmlFor={checkBoxUuid.current} css={labelStyle}>
                <input type="checkbox" css={[inputTagStyle]} id={checkBoxUuid.current}
                    onChange={()=>setIsChecked(!isChecked)} />
                <Checkbox checked={isChecked}/>
                <span css={textStyles}>{label}</span>
            </label>
        </div>
    )
}
