import {ReactComponent as EyeShow} from '@assets/img/ico_eye_show.svg'
import {ReactComponent as EyeHide} from '@assets/img/ico_eye_hide.svg'
import {css} from '@emotion/react'
import {useMemo, useState } from 'react'
import {buttonTagStyle} from '@/styles/global'

const passwordIconStyle = css({
    position: 'absolute',
    top: '10px',
    right: '16px',
    width: '40px',
    height: '40px',
    cursor: 'pointer',
    background: 'transparent',
    backgroundPosition: '50% !important',
    backgroundSize: 'contain !important',
    '@media (max-width: 768px)': {
        width: '24px',
        height: '24px',
        padding: 0,
    },
})

/**
 * パスワードの表示非表示の目のマーク
 */
export const usePasswordIcon = () => {
    const [showPassword, setShowPassword] = useState<boolean>(false)

    const PasswordIcon = useMemo(()=> (
        <button type='button' css={[buttonTagStyle, passwordIconStyle]} onClick={()=>setShowPassword(prev => !prev)}>
            {showPassword
                ? <EyeShow width='100%' height='100%' />
                : <EyeHide width='100%' height='100%' />
            }
        </button>),
    [showPassword])

    const passwordInputType = useMemo(()=> showPassword ? 'text' : 'password',
        [showPassword])


    return {PasswordIcon, passwordInputType}
}
