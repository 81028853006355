import React from 'react'
import { css } from '@emotion/react'
import { fonts } from '../../styles/fonts'

const containerStyle = css({
    backgroundColor: '#353535',
    padding: '8px 30px',
    marginBottom: '20px',
    '@media (max-width: 480px)': {
        marginBottom: '16px',
        padding: '4px 0',
    },
})

const titleStyle = css({
    flex: 1,
    color: 'white',
    fontSize: fonts.size.fontMobile14Pc18,
    fontWeight: '500',
    '@media (max-width: 768px)': {
        textAlign: 'center',
    },
})

export const CenterBlackTitle: React.FC<{
    children: React.ReactNode
}> = (props) => {
    const { children } = props

    return (
        <div css={containerStyle}>
            <div css={titleStyle}>{children}</div>
        </div>
    )
}
