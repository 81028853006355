import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { PurchaseLayout } from '@components/organisms/PurchaseLayout'
import { ReactComponent as ImgThanks } from '@assets/img/img_thanks.svg'
import { pTagStyle } from '@/styles/global'
import { pushDataLayer } from '@/utils/analytics'
import { PromotionModal } from '../atoms/PromotionModal'

const thanksStyle = css({
    fontSize: '22px',
    fontWeight: '600',
    textAlign: 'center',
    color: '#F6AC19',
    letterSpacing: '0.1em',
    margin: '50px 0 30px',
    '@media (max-width: 480px)': {
        fontSize: '18px',
    },
})

const img = css({
    margin: '0 auto',
    display: 'block',
})

const buttonStyles = css({
    color: '#fff',
    fontSize: '18px',
    fontWeight: '500',
    textAlign: 'center',
    letterSpacing: '0.04em',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60px',
    padding: '0 24px',
    background: '#F6AC19',
    border: '2px solid #F6AC19',
    borderRadius: '40px',
    transition: '0.1s',
    cursor: 'pointer',
    maxWidth: '420px',
    width: '100%',
    margin: '50px auto 0',
    boxSizing: 'border-box',
    textDecoration: 'none',
    // boxShadow: '0 2px 4px rgb(0 0 0 / 20%)',
    ':hover': {
        color: '#F6AC19',
        background: '#fff',
    },
    '@media (max-width: 480px)': {
        fontSize: '16px',
        height: '56px',
        margin: '30px auto 0',
        maxWidth: '280px',
    },
})


export const PurchaseThanksPage: React.FC<{deliveryDate: string}> = ({deliveryDate}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    useEffect(() => {
        pushDataLayer({ event: 'view', label: 'viewCheckout' })

        const timer = setTimeout(() => {
            setIsOpen(true)
            // レンダリングで時間がかかるので、その分 遅延させる
        }, 1000 + 500)
        return () => clearTimeout(timer)
    }, [])

    return (
        <PurchaseLayout>
            {/* 注文完了 ====================== */}
            <section>
                <PromotionModal isOpen={isOpen} setIsOpen={setIsOpen} />
                <p css={[pTagStyle, thanksStyle]}>
                    ご注文ありがとうございました。
                </p>
                <ImgThanks css={img} />
                <p>
                    お届け目安は{deliveryDate}です。
                    <br />セットの購入をキャンセルする場合は、以下のアドレスまでお問い合わせください。
                    <br />support@typefood.jp
                </p>

                <a css={buttonStyles} href='https://typefood.jp/' target='_blank' rel="noreferrer">TYPE
                    FOODウェブサイトへ</a>
            </section>
        </PurchaseLayout>
    )
}
