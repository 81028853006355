import {useCallback, useState} from 'react'
import {SWRConfiguration} from 'swr'
import {ErrResult, fetcher, HttpMethod, NestedBody} from './fetcher'

/**
 * すべてのAPIで持っている リクエストとレスポンスに必要なすべての情報オブジェクト
 * ※この型を継承するオブジェクトは、名前のprefixはapiInfoにすること
 */
export type ApiInfo <
    /* レスポンスのBody型 */
    TResponse extends object,
    /* リクエストのURLQueryParameterの型 */
    TQuery extends undefined | Record<string, string> = undefined,
    /* リクエストのBodyの型  */
    TRequestBody extends undefined | NestedBody = undefined,
    > = {
    /* Httpメソッド */
    method: HttpMethod,
    /* queryから、URLの作り方(baseUrlは除く) */
    url: (param: TQuery) => string,
    /* レスポンスの型 オブジェクトを キャストしてOK */
    response: TResponse
    /* リクエストのURLQueryParameterの型 オブジェクトを キャストしてOK */
    query: TQuery
    /* リクエストボディの型 オブジェクトを キャストしてOK */
    requestBody: TRequestBody
}
/**
 * ApiInfoをswrのparameter作成関数に変換する
 * ※この関数の戻り値の関数名のprefixはswrParamsにすること
 * 戻り値の関数は
 * const product = useSWR<Item, ErrResult>(...swrParamsGetItem({itemCode}))
 * みたいに使う
 * @param apiInfo
 */
const toSwrParamsCreator =  <
    /* レスポンスのBody型 */
    TResponse extends object,
    /* リクエストのURLQueryParameterの型 */
    TQuery extends undefined | Record<string, string> = undefined,
    /* リクエストのBodyの型  */
    TRequestBody extends NestedBody | undefined = undefined,
    >(apiInfo: ApiInfo<TResponse, TQuery, TRequestBody>)=>
        (query: TQuery) : [string, (url: string)=>Promise<typeof apiInfo.response>, SWRConfiguration] => [
            apiInfo.url(query),
            (url)=>fetcher<typeof apiInfo.response>(apiInfo.method, url),
            { errorRetryCount: 1, errorRetryInterval: 30000 } as SWRConfiguration
        ]

/**
 * ApiInfoをswrっぽいuseRequest関数に変換する
 * ※この関数の戻り値の関数名のprefixは、use{HTTPMethod}にすること
 * 以下、useRequestの戻り値の説明
 *   request: リクエストの実行。引数はquery parameterとbodyの指定が必須
 *   data: レスポンスのデータ
 *   loading: リクエスト中かどうか
 *   lastRequestedData: 前回リクエストしたリクエストの内容
 *   error: リクエスト後、サーバからのエラー
 * @param apiInfo
 */
const toUseRequest = <
    /* レスポンスのBody型 */
    TResponse extends object,
    /* リクエストのURLQueryParameterの型 */
    TQuery extends undefined | Record<string, string> = undefined,
    /* リクエストのBodyの型  */
    TRequestBody extends NestedBody | undefined = undefined,
    >(apiInfo:ApiInfo<TResponse,TQuery,TRequestBody>) => () => {
        type RequestType = {
            body: typeof apiInfo.requestBody
            query: typeof apiInfo.query
        }
        const [data, setData] = useState< typeof apiInfo.response| null>(null)
        const [lastRequestedData, setLastRequestedData] = useState<RequestType|undefined>()
        const [loading, setLoading] = useState<boolean>(false)
        const [error, setError] = useState<ErrResult|null>(null)

        const request = useCallback(async (submitData: RequestType)=> {
            const { query, body } = submitData
            setLoading(true)
            setLastRequestedData(submitData)

            try {
                const result = await Promise.race([
                    fetcher<typeof apiInfo.response, typeof apiInfo.requestBody>(apiInfo.method, apiInfo.url(query), body),
                    // 10秒でネットワークエラー
                    new Promise((_, reject) =>
                    {setTimeout(() => {
                        reject(new Error('networkErr'))
                    }, 10000)}
                    ),
                ])
                if(result === undefined || result === null){
                    return { data:null, error: {code: 'unknown', message: '予期しないエラーが発生しました'}}
                }
                const okResult = result as TResponse
                setData(okResult)
                setError(null)
                return { data:okResult, error: null}

            } catch (errorResult: ErrResult | any) {
                console.error(errorResult)
                if (errorResult?.code && errorResult?.message) {
                    setError(errorResult)
                }else if(errorResult?.message === 'networkErr' || errorResult?.message === 'Failed to fetch') {
                    setError({ code: 'networkErr', message: 'ネットワークが不安定です' })
                    return { data:null, error: {code: 'networkErr', message: 'ネットワークが不安定です'}}
                } else {
                    setError({ code: 'unknown', message: '予期しないエラーが発生しました' })
                    return { data:null, error: {code: 'unknown', message: '予期しないエラーが発生しました'}}
                }
                setData(null)
                return { data:null, error: errorResult}
            } finally {
                setLoading(false)
            }

        }, [])

        return {request, error, loading, data, lastRequestedData }
    }

export const apiInfoFunc = { toSwrParamsCreator, toUseRequest }
