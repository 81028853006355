import {css} from '@emotion/react'
import {OverLap} from '@components/atoms/Layout/OverLap'
import {ReactComponent as CheckIcon} from '@assets/img/ico_check.svg'


const baseBoxStyle = css({
    width: '16px',
    height: '16px',
    display: 'block',
    borderRadius: '2px',
})

const checkedBoxStyle = css({
    background: '#F6AC19',
    border: '1px solid #F6AC19',
})

const uncheckedBoxStyle = css({
    background: '#fff',
    border: '1px solid #ddd',
})

/**
 * チェックボックスのボックス
 * @param props
 * @constructor
 */
export const Checkbox: React.FC<{
    checked: boolean
}> = (props) => {
    const {checked} = props
    const boxStyle = [
        baseBoxStyle,
        checked ? checkedBoxStyle : uncheckedBoxStyle
    ]

    return (
        <OverLap
            overlap={ checked ? <CheckIcon width="16px" height='16px' /> : null}
            overlapCss={css({paddingTop:'4px', paddingLeft:'1px'})}
            containerCss={css({paddingRight:'5px'})}
        >
            <span css={boxStyle}/>
        </OverLap>
    )
}
