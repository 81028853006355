import React, {useEffect, useState} from 'react'
import {css} from '@emotion/react'
import {Controller, FormProvider, SubmitHandler, useForm} from 'react-hook-form'
import {FieldMessage} from '@components/atoms/FieldMessage'
import {AddressForm} from '@components/organisms/AddressForm'
import {HoverButton} from '@components/atoms/Button/HoverButton'
import {useFirebaseAuthEmailStatus} from '@components/pages/PurchaseFormPage/useFirebaseAuthEmailStatus'
import {usePasswordIcon} from '@components/pages/PurchaseFormPage/usePasswordIcon'
import {useCreateCreditCard} from '@components/atoms/CreditCardForm/useCreateCreditCard'
import {RequiredExplainText} from '@components/pages/PurchaseFormPage/RequiredExplainText'
import {PurchaseLayout} from '../../organisms/PurchaseLayout'
import {CenterBlackTitle} from '../../atoms/CenterBlackTitle'
import {FormLabel} from '../../atoms/FormLabel'
import {CheckboxText} from '../../atoms/CheckBox/CheckboxText'
import {fonts} from '@/styles/fonts'
import {CheckboxButton} from '../../atoms/CheckBox/CheckboxButton'
import {CreditCardForm} from '../../atoms/CreditCardForm/CreditCardForm'
import {usePostReservationConfirmation} from '@/network/api/reservationConfirmation'
import {ErrResult} from '@/network/fetcher'
import {
    bodyFormStyle,
    inputTagStyle,
    pTagStyle,
    sectionTagAfterStyle,
    ulTagStyle
} from '@/styles/global'
import {isValidPassword} from '@/utils/validator/passwordValidator'
import {pushDataLayer, usePushDataLayerOnce} from '@/utils/analytics'
import { CustomerFieldValue, Plan } from './type'
import { priceUtils } from '@/utils/price'
import { OrderConfirmationModal } from './OrderConfirmationModal'
import { FormContext } from '@/store/formContext'
import { usePostOrderConfirmation } from '@/network/api/orderConfirmation'




const textFieldStyle = css({
    width: '100%',
    height: '56px',
    background: '#FFFFFF',
    border: '1px solid #EAEBED',
    boxSizing: 'border-box',
    padding: '12px',
    fontSize:'16px',
    borderRadius: '4px',
    '@media (max-width: 768px)': {
        height: '45px',
    },
})

const listFormStyle = css({
    margin: '16px 0 0',

    li: {
        display: 'flex',
        padding: '0 0 20px',
    },
    '@media (max-width: 768px)': {
        li: {
            display: 'block',
            padding: '0 0 16px'
        }
    }
})
const mailAddressMsgStyle = css({
    display: 'flex',
    flexWrap: 'wrap',
    margin: '6px 0 12px',
})
const txtLinkStyle = css({
    color: '#F6AC19',
    margin: '0 0 0 auto'
})
const selectLabelStyle = css({
    display: 'flex',
    padding: '12px',
    background: '#fff',
    borderBottom: '1px solid #EAEBED',
})
const planDescriptionStyle = css({
    margin: '0',
    fontSize: '14px',
    lineHeight: '1.3',
    color: '#888888',
    fontWeight: 'normal',
})

const plans: Plan[]= [{
    displayName: '9食',
    name: 'light',
    tagLine: '自炊したくない時のピンチヒッター',
    price: 3500,
    isShippingFree: false,
    servingQuantity: 9,
}, {
    displayName: '15食(おすすめ)',
    name: 'standard',
    tagLine: '日常に栄養改善を',
    isShippingFree: true,
    price: 5980,
    servingQuantity: 15,
}, {
    displayName: '30食',
    name: 'premium',
    tagLine: '1日1回の栄養習慣',
    isShippingFree: true,
    price: 12000,
    servingQuantity: 30,
}]

const createPlanExplain = ({
    price, tagLine, isShippingFree
}: Plan) => {
    const priceText = `参考価格 ${priceUtils.format(price)}円前後`
    return `${priceText} / ${isShippingFree ? '送料無料 / ' : '' } ${tagLine}`
}

/**
 * itemを購入するか、もしくは、itemにvariantがあれば、variantを選択して購入するためのフォーム
 * @param props
 * @constructor
 */
export const PurchaseFormPage: React.FC<{
    itemCode: string
    setPage: React.Dispatch<React.SetStateAction<Page>>
    setErrResult: React.Dispatch<React.SetStateAction<ErrResult|undefined>>
}> = (props) => {
    const { setErrResult, setPage, itemCode} = props
    // フォーム全体
    const formMethods = useForm<CustomerFieldValue>({defaultValues: {isSubscribeMailmagazine: true, plan: 'standard'}})
    const {
        register,
        handleSubmit,
        getValues,
        setError,
        control,
        formState: { errors },
    } = formMethods
    const pushDataLayerOnce = usePushDataLayerOnce()
    const {setValidConfirmationResult, htmlDataset} = FormContext.useContext()
    // メールフォーム関連
    const [mailAddressMsg, setMailAddressMsg] = useState<React.ReactNode | undefined>()
    const emailStatus = useFirebaseAuthEmailStatus()
    useEffect(() => {
        setError('email', {})
        if(emailStatus.authMethodStatus === 'newUser') {
            setMailAddressMsg(undefined)
        }
        if(emailStatus.authMethodStatus === 'authBySns') setMailAddressMsg(
            <div css={mailAddressMsgStyle}>
                <p css={[pTagStyle, css({color:'#DC3251'})]}>このメールアドレスはパスワードの設定が必要です。</p>
                <a css={txtLinkStyle} href="https://id.vitanote.jp/password_reset_email"> パスワードの再設定はこちら</a>
            </div>
        )
        if(emailStatus.authMethodStatus === 'invalidEmail') setError('email', {type: 'manual', message: '有効なメールアドレスをご入力ください。'})

    // authMethodStatusの状態のみ監視
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailStatus.authMethodStatus])
    const emailRegister = register('email', {
        validate:()=> emailStatus.authMethodStatus === 'invalidEmail' ? '有効なメールアドレスをご入力ください。' : true,
        required: '入力してください。',
        onChange: () => {
            pushDataLayerOnce({
                event: 'input',
                label: 'inputMailAddress'
            })
        },
        onBlur: async (event: {target: {value: string}}) => {
            await emailStatus.checkEmailAuthStatus(event.target.value)
        }
    })

    // パスワードフォーム関連
    const { PasswordIcon, passwordInputType } = usePasswordIcon()
    const passwordRegister = register('password', {
        required: '入力してください。',
        onChange: () => {
            pushDataLayerOnce({
                event: 'input',
                label: 'inputPassword'
            })
        },
        onBlur:() => {
            setError('password', {})
        },
        minLength: { message:'半角英数字、6文字以上で入力してください。', value: 6 },
        validate: value => isValidPassword(value) ? true : '半角英数字、6文字以上で入力してください。',
    })
    // クレジットカードフォーム関連
    const { createCreditCard, stripeError } = useCreateCreditCard()
    // 請求先と届け先が違う
    const [isUseAnotherAddressForShip, setIsUseAnotherAddressForShip] = useState<boolean>(false)

    // 注文の確認
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const postReservationConfirmation = usePostReservationConfirmation()
    const postOrderConfirmation = usePostOrderConfirmation()
    const onSubmitConfirm: SubmitHandler<CustomerFieldValue> = async (data) => {
        setIsLoading(true)
        try{
            const {password, email}  = JSON.parse(JSON.stringify(data)) as CustomerFieldValue
            // アカウントあるのにログイン失敗するのはここで弾く
            const loginStatus = await emailStatus.checkLoginBeforeOrderConfirm(email, password)
            if(loginStatus.status === 'loginFailure'){
                setError('password', {type: 'missPassword', message:'パスワードが間違っています。'})
                return
            }
            if(loginStatus.status === 'networkErr'){
                setErrResult({code: 'networkErr', message: 'ネットワークが不安定です'})
                return
            }
            // クレジットカードを作成
            const creditCardResult = await createCreditCard()
            // ネットワークエラーなど
            if(creditCardResult.customError){
                setErrResult(creditCardResult.customError)
                return
            }
            if(!creditCardResult.data) return
            if(!itemCode) return

            const formValues = getValues()
            if(htmlDataset.isDao){
                if(!formValues.startupSupportCode){
                    setError('startupSupportCode', {type: 'required', message:'スタートアップ支援コードを入力してください。'})
                    return
                }
            }
            const shipAddress = isUseAnotherAddressForShip ?  formValues.shipAddress : formValues.billAddress
            if(!shipAddress) return

            const requestBody = {
                itemCode,
                isSubscribeMailmagazine: formValues.isSubscribeMailmagazine,
                billAddress: formValues.billAddress,
                shipAddress,
                mzdaoCode: formValues.startupSupportCode || undefined,
                couponCode: formValues.couponCode || undefined,
                planName: formValues.plan,
            }


            const result = htmlDataset.isOrder
                ? await postOrderConfirmation.request({body: { orderConfirmation: { ...requestBody } }, query: undefined})
                : await postReservationConfirmation.request({body: { reservation: { ...requestBody } }, query: undefined})

            if(result.data){
                setValidConfirmationResult({
                    ...result.data,
                    ...formValues,
                    itemCode,
                    shipAddress,
                    couponCode: formValues.couponCode || undefined,
                    startupSupportCode: formValues.startupSupportCode || undefined,
                    userIdToken: loginStatus.userIdToken,
                    creditCard: creditCardResult.data,
                    plan: plans.find(plan => getValues().plan === plan.name) || plans[1]
                })
            }else if(result.error){
                if(result.error.code && result.error.message){
                    setErrResult(result.error)
                }
            }
        }finally {
            setIsLoading(false)
        }
    }

    const couponCodeRegister = register('couponCode', {
        required: false,
        onChange: () => {
            pushDataLayerOnce({
                event: 'input',
                label: 'inputCouponCode'
            })
        },
        minLength: { message:'半角数字8文字 または 半角英数字10文字で入力してください。', value: 8 }
    })
    
    const startupSupportCodeRegister = register('startupSupportCode', {
        required: false,
        onChange: () => {
            pushDataLayerOnce({
                event: 'input',
                label: 'inputStartupSupportCode'
            })
        },
        minLength: { message:'英数字12桁で入力してください。', value: 12 },
        maxLength: { message:'英数字12桁で入力してください。', value: 12 }
    })

    const isExistsError = errors?.email?.message || errors?.password?.message ||
        errors?.shipAddress?.firstName?.message || errors?.shipAddress?.lastName?.message || errors?.shipAddress?.zipcode?.message || errors?.shipAddress?.prefectureId?.message || errors?.shipAddress?.address1?.message || errors?.shipAddress?.address2?.message || errors?.shipAddress?.phoneNumber?.message ||
        errors?.billAddress?.firstName?.message || errors?.billAddress?.lastName?.message || errors?.billAddress?.zipcode?.message || errors?.billAddress?.prefectureId?.message || errors?.billAddress?.address1?.message || errors?.billAddress?.address2?.message || errors?.billAddress?.phoneNumber?.message ||
        errors?.couponCode?.message || errors?.startupSupportCode?.message

    return (
        <PurchaseLayout>
            <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(async (data)=>{
                    try{
                        await onSubmitConfirm(data)
                    }catch (e){
                        console.error(e)
                    }
                })}>
                    <div css={css({ display: 'flex', flexDirection: 'column' })}>
                        <RequiredExplainText/>
                        <section>
                            <CenterBlackTitle>アカウント情報</CenterBlackTitle>
                            <FieldMessage isErr>
                                ※以下の内容でアカウントの仮登録を行います。定期便を利用する方の情報を正しくご入力ください<br/>
                                ※アプリにログインする際に必要になりますので、登録内容は忘れないようにしてください
                            </FieldMessage>
                            <ul css={[ulTagStyle, listFormStyle]} >

                                <li>
                                    <FormLabel isRequired>メールアドレス</FormLabel>
                                    <div css={bodyFormStyle}>
                                        <input placeholder="typefood@sample.jp" type='text' css={[inputTagStyle, textFieldStyle]} {...emailRegister} />
                                        <FieldMessage>{errors.email?.message}</FieldMessage>
                                        <FieldMessage isErr={false}>{mailAddressMsg}</FieldMessage>
                                    </div>
                                    <br/>
                                </li>
                                <li>
                                    <FormLabel isRequired>パスワード</FormLabel>
                                    <div css={bodyFormStyle}>
                                        <div css={css({ width: '100%', position: 'relative'})}>
                                            <input
                                                placeholder="半角英数字6文字以上"
                                                type={passwordInputType} css={[inputTagStyle, textFieldStyle]}
                                                {...passwordRegister} />
                                            {PasswordIcon}
                                            {(errors.password) && (<div>
                                                <FieldMessage isErr>
                                                    <>
                                                        {errors.password.message}
                                                        {errors.password.type === 'missPassword' && (
                                                            <>
                                                                <p css={pTagStyle}>このメールアドレスはVITANOTEアプリで登録済です</p>
                                                                <p css={pTagStyle}>VITANOTEアプリのログイン情報と同じ情報を入力してください</p>
                                                                <a css={txtLinkStyle} href="https://id.vitanote.jp/password_reset_email"> パスワードをお忘れの方</a>
                                                            </>
                                                        )}
                                                    </>
                                                </FieldMessage>
                                            </div>)}
                                        </div>
                                        <p
                                            css={css({
                                                fontSize: fonts.size.font12,
                                                color: '#666',
                                                margin: '2px 0 0',
                                            })}
                                        >
                                            {' '}
                                            半角英数字6文字以上で入力してください
                                        </p>

                                    </div>
                                    
                                </li>
                                <li>
                                    <div css={css({margin: '4px 0 0',})}>
                                        <Controller
                                            name="isSubscribeMailmagazine"
                                            control={control}
                                            render={({ field })=><CheckboxText label="TYPE FOODからのお知らせを受け取る" {...field}/>}
                                        />
                                    </div>
                                </li>
                            </ul>
                            
                        </section>
                        <section css={sectionTagAfterStyle}>

                            
                            <CenterBlackTitle>お客様情報</CenterBlackTitle>

                            <ul css={[ulTagStyle, listFormStyle]} >
                                <AddressForm
                                    nameFirstName="billAddress.firstName"
                                    nameLastName="billAddress.lastName"
                                    nameZipCode="billAddress.zipcode"
                                    namePrefectureId="billAddress.prefectureId"
                                    nameAddress1="billAddress.address1"
                                    nameAddress2="billAddress.address2"
                                    namePhoneNumber='billAddress.phoneNumber'
                                />
                              
                            </ul>
                            <div css={css({
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            })}>
                                <CheckboxButton
                                    label="上記と別の住所へお届けする"
                                    setIsChecked={setIsUseAnotherAddressForShip}
                                    isChecked={isUseAnotherAddressForShip} />
                            </div>
                        </section>
                        {/* お届け先が違う場合 */}
                        {isUseAnotherAddressForShip && (
                            <section css={sectionTagAfterStyle}>
                                <CenterBlackTitle>お届け先</CenterBlackTitle>
                                <ul css={[ulTagStyle, listFormStyle]} className="list_form">
                                    <AddressForm
                                        nameFirstName="shipAddress.firstName"
                                        nameLastName="shipAddress.lastName"
                                        nameZipCode="shipAddress.zipcode"
                                        namePrefectureId="shipAddress.prefectureId"
                                        nameAddress1="shipAddress.address1"
                                        nameAddress2="shipAddress.address2"
                                        namePhoneNumber='shipAddress.phoneNumber'
                                    />
                                </ul>
                            </section>
                        )}

                        <section css={sectionTagAfterStyle}>
                            <CenterBlackTitle>
                                クレジットカード情報
                            </CenterBlackTitle>
                            <p
                                css={css({
                                    fontSize: fonts.size.fontMobile12Pc16,
                                    margin: '0 0 16px',
                                })}
                            >
                                {' '}
                                すべての取引は暗号化され、安全に扱われます。
                            </p>
                            <CreditCardForm stripeError={stripeError} />
                        </section>

                       
                        <section css={sectionTagAfterStyle}>
                            <CenterBlackTitle>食数の選択(2回目以降)</CenterBlackTitle>
                            <div css={css({
                                background: '#ffffff',
                                borderRadius: '4px',
                                border: '1px solid #EAEBED',
                                'label:last-of-type': {
                                    border: '0'
                                }
                            })}>
                                {plans.map((plan) => (
                                    <label
                                        key={plan.name} htmlFor={plan.name}
                                        css={[selectLabelStyle]}>
                                        <input
                                            id={plan.name}
                                            type="radio"
                                            value={plan.name}
                                            {...register('plan', {required: '選択してください。'})}
                                            css={css({
                                                accentColor: '#F6AC19'
                                            })}
                                        />
                                        <div css={css({
                                            margin: '0 0 0 1em',
                                            fontWeight: 'bold',
                                        })}>
                                            {plan.displayName}
                                            <p css={[planDescriptionStyle]}>{createPlanExplain(plan)}</p>
                                        </div>
                                    </label>)
                                )}
                            </div>
                        </section>

                        {htmlDataset.isVisibleCoupon && (
                            <section css={sectionTagAfterStyle}>
                                <CenterBlackTitle>
                                    クーポン情報
                                </CenterBlackTitle>
                                <ul css={[ulTagStyle, listFormStyle]}>
                                    <li>
                                        <FormLabel isRequired={false}>クーポンコード</FormLabel>
                                        <div css={[bodyFormStyle, css({maxWidth:'300px'})]}>
                                            <input
                                                placeholder="クーポンコード"
                                                type="text"
                                                css={[inputTagStyle, textFieldStyle]}
                                                {...couponCodeRegister}
                                            />
                                            <FieldMessage isErr>{errors.couponCode?.message}</FieldMessage>
                                        </div>
                                    </li>
                                </ul>
                            </section>
                        )}
                        {htmlDataset.isDao && (<>
                            <section css={sectionTagAfterStyle}>
                                <CenterBlackTitle>スタートアップ支援コード</CenterBlackTitle>
                                <ul css={[ulTagStyle, listFormStyle]}>
                                    <div css={[bodyFormStyle, css({maxWidth:'300px'})]}>
                                        <input
                                            placeholder="0000000000"
                                            type="text"
                                            css={[inputTagStyle, textFieldStyle]}
                                            {...startupSupportCodeRegister}
                                        />
                                        <FieldMessage isErr>{errors.startupSupportCode?.message}</FieldMessage>
                                    </div>
                                </ul>
                            </section>
                            <section>
                                <p css={css({
                                    margin: '16px 0 0',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                })}>
                                    MZDAOの方へ注意事項</p>
                                <p css={css({
                                    margin: '0 0 12px 0',
                                    fontSize: '14px',
                                })}>
                                    本ページ以外から購入されても支援ポイントがつかないのでご注意ください
                                </p>
                            </section>
                        </>)}
                    </div>

                    <HoverButton
                        type="submit"
                        label="ご注文内容を確認する"
                        loading={postReservationConfirmation.loading || isLoading}
                        onClick={()=>{
                            pushDataLayer({
                                event: 'click',
                                label: 'clickOrderConfirmationButton'
                            })
                        }}/>
                    {isExistsError && ( <div css={css({
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                    })}><FieldMessage isErr>入力内容に誤りがありました。入力内容をご確認ください。</FieldMessage></div>) }
                </form>
                <OrderConfirmationModal
                    close={()=>{setValidConfirmationResult(undefined)}}
                    setPage={setPage}
                    setErrResult={setErrResult}
                    isOrder={htmlDataset.isOrder}
                    isDao={htmlDataset.isDao}
                    deliveryDate={htmlDataset.deliveryDate}
                />
            </FormProvider>
        </PurchaseLayout>
    )
}
