import React from 'react'
import { css } from '@emotion/react'
import { colors } from '../../styles/colors'

const containerStyles = css({
    backgroundColor: colors.background,
    maxWidth: '100vw',
    minHeight: '100vh',
    padding: '60px',
    paddingRight: '40px',
    paddingLeft: '40px',
    color: colors.text,
    '@media (max-width: 639px)': {
        padding: '50px',
        paddingRight: '20px',
        paddingLeft: '20px',
    },
})

// ご注文フォームの背景
// 画面の幅はココで吸収したい気持ち
export const PurchaseLayout: React.FC<{
    children: React.ReactNode
}> = (props) => {
    const { children } = props

    return (
        <div css={containerStyles}>
            <div css={css({ maxWidth: '530px', margin: '0 auto' })}>
                <div
                    css={css({
                        textAlign: 'center',
                        marginBottom: 50,
                        '@media (max-width: 530px)': {
                            marginBottom: 20,
                        },
                    })}
                >
                    <div
                        css={css({
                            fontSize: '36px',
                            fontWeight: '600',
                            letterSpacing: '.1em',

                            '@media (max-width: 530px)': {
                                fontSize: '24px',
                            }
                        })}
                    >
                        【ご注文フォーム】{' '}
                    </div>
                </div>
                {children}
            </div>
        </div>
    )
}
