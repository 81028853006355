import React from 'react'

/**
 * 初期化が不要なuseContextを作成する
 */
const createContextNoDefault = <ContextType>() => {
    const contextNoDefault = React.createContext<ContextType | undefined>(
        undefined,
    )
    const useContextNoDefault = () => {
        const context = React.useContext(contextNoDefault)
        if (!context)
            throw new Error(
                'useContextNoDefault must be inside a Provider with a value',
            )

        return context
    }

    return {
        useContext: useContextNoDefault,
        Provider: contextNoDefault.Provider as React.Provider<ContextType>,
    } as const
}

export const contextUtils = {
    createContextNoDefault,
}
