/**
 * GTMに送信するイベントとイベントを送信する関数
 * イベントはこちらを参照↓
 * https://docs.google.com/spreadsheets/d/1_K4yc28v4ECygFHxG6jkXiwtkNt8RNa7G3AazoO0XfQ/edit#gid=0
 */

import {useCallback, useRef} from 'react'

/**
 * ページなどの標示イベント
 */
type ViewEvent = {
    event: 'view'
    label:
        | 'viewOrderConfirmation' // 注文確認画面-ページビュー
        | 'viewCheckout' // 注文完了画面-ページビュー
}

/**
 * ボタンなどのクリックイベント
 */
type ClickEvent = {
    event: 'click'
    label:
        | 'clickOrderConfirmationButton' // 注文確認ボタン-クリック
        | 'clickCheckoutButton' // 注文完了ボタン-クリック
}

/**
 * 一文字入力したら発火するイベント
 * コンバーションが悪いときに、どこの入力欄で引っかかているのか見るようなので、入力内容は送らない
 */
type InputEvent = {
    event: 'input'
    label:
        | 'inputLastName' // 注文フォーム お名前（姓）-入力
        | 'inputFirstName' //  注文フォーム お名前（名）-入力
        | 'inputPostcode' // 注文フォーム 郵便番号-入力
        | 'inputAddress' // 注文フォーム 住所-入力
        | 'inputPhoneNumber' // 注文フォーム 電話番号-入力
        | 'inputMailAddress' // 注文フォーム メールアドレス-入力
        | 'inputPassword' // 注文フォーム パスワード-入力
        | 'inputCreditcard' // 注文フォーム クレカ情報（カード番号）-入力
        | 'inputCouponCode' // 注文フォーム クーポン情報 -入力
        | 'inputStartupSupportCode' // 注文フォーム スタートアップ支援コード-入力
}

type SelectEvent = {
    event: 'select'
    label: 'selectVariants' // フレーバーなどのvariantsの選択
}

/**
 * GTMに登録可能なイベントタイプ
 */
type DataLayerType =
    | ViewEvent
    | ClickEvent
    | InputEvent
    | SelectEvent

/**
 * GTMにイベントを追加する関数
 * Formが掲載されているLPにwindow.dataLayerがなければ動かないので注意
 * @param data
 */
export const pushDataLayer = (data: DataLayerType): void => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(data)
}

/**
 * このhooksを利用中のコンポーネントでは、GTMに同じラベルのイベントを一度だけしか送らない
 */
export const usePushDataLayerOnce = () => {
    const pushedEventRef = useRef<DataLayerType[]>([])

    /**
     * GTMに同じイベントを一度だけしか送らない
     */
    return useCallback((data: DataLayerType)=>{
        const currentLabel = data.label
        const isAlreadyPushed = pushedEventRef.current.find(pushed => pushed.label === currentLabel) !== undefined
        if(!isAlreadyPushed){
            pushDataLayer(data)
            pushedEventRef.current.push(data)
        }
    },[])
}
