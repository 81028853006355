import {ApiInfo, apiInfoFunc} from '../apiInfo'


export type UserAddress = {
    firstName: string
    lastName: string
    zipcode: string
    prefectureId: number|string
    address1: string, // 市区町村
    address2: string, // 番地など
    phoneNumber: string
}
/**
 * 注文の確認
 */
export type ReservationConfirmation = {
    reservation: {
        itemCode: string
        couponCode: string | undefined
        isSubscribeMailmagazine: boolean,
        shipAddress: UserAddress,
        billAddress: UserAddress
        planName: 'light' | 'standard' |'premium'
        mzdaoCode: string | undefined
    }
}

export type ReservationConfirmationResult = {
    totalAmount: number
    itemPrice: number
    discountPrice: number
}

// APIのURLに対して、型を確定させる
const apiInfoReservationConfirmation: ApiInfo<ReservationConfirmationResult, undefined, ReservationConfirmation > = {
    method: 'POST',
    url: () => '/web_ec_v2/tf/reservation/confirmation',
    response: {} as ReservationConfirmationResult,
    query: undefined,
    requestBody: {} as ReservationConfirmation
}

export const usePostReservationConfirmation = apiInfoFunc.toUseRequest(apiInfoReservationConfirmation)
