import React from 'react'
import {fonts} from '../../styles/fonts'
import {pTagStyle} from '@/styles/global'




export const FieldMessage: React.FC<{
    children: React.ReactNode | undefined | string
    isErr?: boolean
}> = (props) => {
    const {children, isErr = true} = props

    if(!children) return null

    return (
        <p css={[pTagStyle,{
            fontSize: fonts.size.font12,
            fontWeight: 'bold',
            color:  isErr ? '#DC3251': '#000000',
            margin: '2px 0 0',
        }]}>
            {' '}
            {children}
        </p>
    )
}
