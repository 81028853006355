import { useMemo } from 'react'
import {rootDiv} from '@/utils/htmlElements'

/**
 * HTMLから取得するデータセット
 */
export type HtmlDataset = {
    // 商品コード
    itemCode: string
    // 注文フォームでクーポン情報の入力フォームを表示するかどうか(trueで表示する)
    isVisibleCoupon:boolean
    // Daoようかどうか スタートアップ支援コードを表示するかどうか & 商品の表示内容を変更する
    isDao:boolean
    // 予約か注文か
    isOrder:boolean
    // お届け目安
    deliveryDate:string
}
/**
 * htmlにあるdata-item-codeを取り出す
 */
const getItemCode = ():string => {
    if(rootDiv?.dataset?.itemCode === undefined){
        throw Error('data-item-codeがありません')
    }

    return rootDiv?.dataset?.itemCode
}


/**
 * htmlにあるdata-is-visible-couponを取り出す
 */
const getIsVisibleCoupon = ():boolean => {
    if(rootDiv?.dataset?.isVisibleCoupon === undefined){
        console.info('data-is-visible-couponがありません')
    }

    return rootDiv?.dataset?.isVisibleCoupon === 'true'
}


/**
 * Daoコーを取得します
 */
const getIsDao = ():boolean => {
    if(rootDiv?.dataset?.isVisibleStartupSupportCode === undefined){
        console.info('data-is-daoがありません')
    }

    return rootDiv?.dataset?.isDao === 'true'
}
const getIsOrder = ():boolean => {
    if(rootDiv?.dataset?.isOrder === undefined){
        console.info('data-is-orderがありません')
    }

    return rootDiv?.dataset?.isOrder === 'true'
}
/**
 * お届け目安を取得します
 */
const getDeliveryDate = ():string => {
    const deliveryDate = rootDiv?.dataset?.deliveryDate
    if(!deliveryDate){
        console.info('data-delivery-dateがありません')
        return '注文後5~10日'
    }

    return deliveryDate
}



/**
 * htmlからデータセットを取り出しておく
 */
export const useHtmlDataset = ():HtmlDataset => {

    // ご購入確認画面の商品とともに説明に追加するテキスト
    const htmlDataset: HtmlDataset = useMemo(() => {
        try {
            return  {
                itemCode: getItemCode(),
                isVisibleCoupon: getIsVisibleCoupon(),
                isDao: getIsDao(),
                isOrder: getIsOrder(),
                deliveryDate: getDeliveryDate()
            }
        }catch (e){
            // eslint-disable-next-line no-alert
            alert(e)
            throw e
        }

    }, [])

    return htmlDataset
}