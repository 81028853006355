import { Plan } from '@components/pages/PurchaseFormPage/type'
import { HtmlDataset } from '@/configure/htmlDataset'
import { CreatedCreditCard } from '@/network/api/stripePublicKey'
import { UserAddress } from '@/network/api/reservationConfirmation'
import { contextUtils } from '@/utils/context'

/**
 * 有効な登録内容
 */
export type ValidConfirmationResult = {
    email: string,
    password: string,
    creditCard: CreatedCreditCard
    // アカウントがあるユーザのみ存在。なければ新規ユーザ
    userIdToken?: string
    plan: Plan
    shipAddress: UserAddress,
    billAddress: UserAddress,
    startupSupportCode: string | undefined,
    couponCode: string | undefined,
    isSubscribeMailmagazine: boolean,
    // 購入金額
    totalAmount: number
    itemPrice: number
    discountPrice: number
    // 商品コード
    itemCode: string
}


export const FormContext = contextUtils.createContextNoDefault<{
    htmlDataset: HtmlDataset
    // ユーザの有効な注文内容
    validConfirmationResult: ValidConfirmationResult | undefined
    setValidConfirmationResult: (confirmationResult?:ValidConfirmationResult| undefined) => void
}>()