import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { ConfirmBlackTitle } from '@components/atoms/ConfirmBlackTitle'
import Modal from '@components/atoms/Modal'
import { HoverButton } from '@components/atoms/Button/HoverButton'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { ReactComponent as TopItemImg } from '@assets/img/img_top_modal.svg'
import { RecurringPlan } from '@components/pages/PurchaseFormPage/OrderConfirmationModal/RecurringPlan'
import { getPrefectureName } from '@/configure/prefectures'
import { ErrResult } from '@/network/fetcher'
import { firebaseAuth } from '@/network/firebase/firebaseAuth'
import { usePostReservationCheckout } from '@/network/api/reservationCheckout'
import { pTagStyle, ulTagStyle } from '@/styles/global'
import { rootDiv } from '@/utils/htmlElements'
import { pushDataLayer } from '@/utils/analytics'
import { priceUtils } from '@/utils/price'
import { FormContext } from '@/store/formContext'
import { usePostOrderCheckout } from '@/network/api/orderCheckout'


const headTtl = css({
    textAlign: 'center',
    fontWeight: '500',
    fontSize: '18px',
    padding: '4px',
    margin: '8px 0',
    color: '#fff',
    background: '#333333',
})

const leadTextStyle = css({
    fontSize: '14px',
    textAlign: 'center',
    letterSpacing: '-0.05rem;'
})

const containerStyle = css({
    padding: '0 24px 30px 0',
    width: '100%',
    height: 'calc(100% - 80px)',
    overflow: 'hidden',
    overflowY: 'scroll',
})

const flowContainerStyle = css({
    margin: '8px 0 0',
    display: 'flex',
    justifyContent: 'center',
    listStyle: 'none'
})

const flowItemStyle = css({
    textAlign: 'center',
})

const flowItemBorderStyle = css({
    margin: '15px 0 0',
    width: '40px',
    height: '2px',
    background: '#c2c2c2'
})

const flowItemNumberStyle = css({
    position: 'relative',
    margin: '0 auto',
    display: 'block',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    background: '#c2c2c2',
    color: '#fff',
    fontSize: '12px',

    span: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
    }
})

const flowItemNumberCurrentStyle = css({
    background: '#F6AC19',
})

const flowItemTextStyle = css({
    fontSize: '12px',
    color: '#666',
})

const flowItemTextCurrentStyle = ({
    color: '#F6AC19',
})

const itemInfoStyle = css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginBottom: '24px',
    gap: '0 16px',

    '@media (max-width: 480px)': {
        display: 'block',
    }
})

const itemInfoTitletStyle = css({
    fontWeight: '600',
    margin: '0 0 4px 0',
    fontSize: '16px',
})

const itemInfoTextStyle = css({
    width: 'max-content',
    fontSize: '14px',

    '@media (max-width: 480px)': {
        width: '100%',
    }
})

const itemInfoListStyle = css({
    padding: '0 0 0 1em',
    margin: 0,
})

const expectedShippingDateStyle = css({
    width: '100%',
    textAlign: 'center',

    span: {
        color: '#ff5e5e',
    },
})

const itemPriceBoxStyle = css ({
    padding: '16px 0 8px 0',
    border: '1px solid #c2c2c2',
    borderWidth: '1px 0',
})

const itemListItemStyle = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '0 0 8px 0',
    width: '100%',

    dt: {
        margin: 0,
        width: '43%'
    },
    dd: {
        margin: 0,
        width: '57%',
        textAlign: 'right',
    }
})

const itemListTotalPriceStyle = css({
    fontWeight: 'bold',
    color: '#ff5e5e',

    span: {
        fontSize: '18px'
    }
})

const confirmTextTitleStyle = css({
    margin: '50px 0 18px 0',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '18px',
})

const listInfoStyle = css({
    margin: '10px 0 8px 0',
    borderTop: '1px solid #d9d9d9',

    li: {
        display: 'flex',
        borderBottom: '1px solid #d9d9d9',
        background: '#fff'
    }
})

const confirmTextStyle = css({
    margin: '8px 0 0',
    fontSize: '12px',
})

const confirmAttentionTextStyle = css({
    margin: '8px 0 0',
    fontSize: '12px',
    color: '#ff5e5e'
})

const contentStyle = css({
    padding: '10px',
    alignItems: 'center',
    width: '60%',
    minWidth: '10px',
    font: '500 13px/1.3em Hiragino Kaku Gothic Pro, sans-serif',
    fontSize: '14px',
    color: '#333333',
    wordBreak: 'break-all',
})

const titleStyle = css({
    padding: '10px',
    width: '40%',
    font: '600 13px/1 Hiragino Kaku Gothic Pro, sans-serif',
    background: '#f4f4f4',
    fontSize: '14px',
})

const productImgStyle = css({
    width: '40%',
    height: 'auto',

    '@media (max-width: 480px)': {
        width: '100%',
    }
})

/**
 * おためしセットの名前を取得する
 * https://github.com/YUKASHIKADO/typefood-lp/issues/28#issuecomment-1988143498
 * @param isDao
 * @param isOrder
 */
const getOrderName = ({isDao, isOrder}: {isDao: boolean, isOrder: boolean}) => {
    if(isOrder){
        return '【5日間だけの最安値】初回限定おためしセット'
    }
    if(isDao){
        return '新発売記念おためしセット'
    }
    return '新発売記念スペシャルセット'

}

export const OrderConfirmationModal: React.FC<{
    close: () => void
    setPage: React.Dispatch<React.SetStateAction<Page>>
    setErrResult: React.Dispatch<React.SetStateAction<ErrResult|undefined>>
    isDao: boolean
    isOrder: boolean
    deliveryDate: string
}> = (props) => {
    const {close, setErrResult, setPage, isDao, isOrder, deliveryDate} = props
    const {validConfirmationResult} = FormContext.useContext()
    const [isLoading, setIsLoading] = useState(false)
    const postReservationCheckout = usePostReservationCheckout()
    const postOrderCheckout = usePostOrderCheckout()

    const orderName = getOrderName({isDao, isOrder})

    useEffect(() => {
        // orderConfirmationInfoがあるときにモーダルがOpenになるので、orderConfirmationInfoがあるときOpenとする
        if(validConfirmationResult){
            pushDataLayer({event: 'view', label: 'viewOrderConfirmation'})
        }
    }, [validConfirmationResult])

    if(!validConfirmationResult){
        return null
    }
    const { shipAddress, billAddress, email, isSubscribeMailmagazine, password, creditCard, userIdToken  } = validConfirmationResult

    const onClickCheckout = async () => {
        pushDataLayer({
            event: 'click',
            label: 'clickCheckoutButton'
        })
        try {
            setIsLoading(true)
            const idToken = userIdToken || await Promise.race([
                createUserWithEmailAndPassword(firebaseAuth, email, password)
                    .then(user => user.user.getIdToken())
                    .catch((error) => {
                        if(error.code === 'auth/network-request-failed'){
                            throw { code: 'NetworkErr', message: 'ネットワークエラーが発生しました。安定した接続環境で再度お試しください。'}
                        }else{
                            throw { code: error.code, message: `ユーザの作成に失敗しました。お手数ですが、最初からやり直しよろしくお願いします。 ErrCode:${error.code || ''}`}
                        }
                    }),
                // 10秒でネットワークエラー
                new Promise((_, reject) =>
                {setTimeout(() => {
                    reject(new Error('networkErr'))
                }, 10000)}
                ),
            ]) as string // 成功したらidTokenが返ってくる

            const requestBody =  {
                idToken,
                cardToken: creditCard.token,
                itemCode: validConfirmationResult.itemCode,
                planName: validConfirmationResult.plan.name,
                mzdaoCode: validConfirmationResult.startupSupportCode,
                couponCode: validConfirmationResult.couponCode,
                isSubscribeMailmagazine,
                shipAddress,
                billAddress
            }

            const result = isOrder
                ? await postOrderCheckout.request({ query: undefined, body: { checkout: requestBody }})
                : await postReservationCheckout.request({ query: undefined, body: { reservation: requestBody }})
            if(result.data){
                setPage('thanks')
                // 遷移前のスクロール位置を覚えてしまっているので対策
                window.scrollTo({
                    top: rootDiv?.offsetTop || 0,
                })
            }
            if(result.error){
                if(result.error.code && result.error.message){
                    setErrResult(result.error)
                }
            }

        }catch (e: any|ErrResult) {
            if(e?.message === 'networkErr'){
                setErrResult({
                    code: 'networkErr',
                    message: 'ネットワークが不安定です'
                })
                return
            }
            
            if(e?.code || e?.message){
                setErrResult(e)
            }
        }finally {
            setIsLoading(false)
        }
    }

    return (
        <Modal close={close} isOpen maxWidth='540px' maxHeight='900px'>
            <p css={[pTagStyle, headTtl]}>ご注文内容の確認</p>
            <div css={containerStyle}>
                <div css={flowContainerStyle}>
                    <div css={flowItemStyle}>
                        <p css={flowItemNumberStyle}><span>1</span></p>
                        <span css={flowItemTextStyle}>情報入力</span>
                    </div>
                    <div css={flowItemBorderStyle} />
                    <div css={flowItemStyle}>
                        <p css={[flowItemNumberStyle,flowItemNumberCurrentStyle]}><span>2</span></p>
                        <span css={[flowItemTextStyle,flowItemTextCurrentStyle]}>内容確認</span>
                    </div>
                    <div css={flowItemBorderStyle} />
                    <div css={flowItemStyle}>
                        <p css={flowItemNumberStyle}><span>3</span></p>
                        <span css={flowItemTextStyle}>注文確定</span>
                    </div>
                </div>
                <p css={leadTextStyle}>ご注文はまだ確定しておりません。<br />内容をご確認の上、「注文を確定する」ボタンを押してください</p>

                {/* 商品の内容 */}
                <div css={itemInfoStyle}>
                    <TopItemImg css={productImgStyle} />
                   
                    <div css={itemInfoTextStyle}>
                        <p css={itemInfoTitletStyle}>
                            {orderName}
                        </p>
                        {isOrder ? (
                            <ul css={itemInfoListStyle}>
                                <li>バターチキンカレー</li>
                                <li>プチっと玄米 五目ご飯</li>
                                <li>えんどう豆ミートのボロネーゼ</li>
                                <li>国産さつまいもの味噌煮込み</li>
                                <li>野菜炒めの素 生姜焼き</li>
                                <li>酵素野菜ジュース トマト</li>
                                <li>かぼちゃスープ</li>
                                <li>おからクッキー プレーン</li>
                                <li>宇治抹茶ラテ</li>
                                <li>蒸しパン ココア</li>
                                <li>栄養スクリーニング検査 MYTYPE</li>
                            </ul>
                        ): (<ul css={itemInfoListStyle}>
                            <li>蒸しパン ココア</li>
                            <li>バターチキンカレー</li>
                            <li>ほうれん草のクリームパスタ</li>
                            <li>国産ケールと大麦若葉のスムージー</li>
                            <li>宇治抹茶ラテ</li>
                            <li>おからクッキー チョコ</li>
                            <li>豚汁</li>
                            <li>ローストアマニとゴマの和風ドレッシング</li>
                            <li>春雨スープ ごま味噌担々風</li>
                            <li>プチっとライス わかめ醤油</li>
                            <li>栄養スクリーニング検査 MYTYPE</li>
                        </ul>)}
                    </div>
                    <p css={expectedShippingDateStyle}>お届け目安：<span>{deliveryDate}</span></p>
                </div>

                <div css={itemPriceBoxStyle}>
                    <dl css={itemListItemStyle}>
                        <dt>商品金額(税込)</dt>
                        <dd>4,125円 → <span
                            css={{ color: 'red' }}>{priceUtils.format(validConfirmationResult.itemPrice)}円</span></dd>
                    </dl>

                    {validConfirmationResult.discountPrice > 0 && (
                        <dl css={itemListItemStyle}>
                            <dt>クーポン</dt>
                            <dd>-{priceUtils.format(validConfirmationResult.discountPrice)}円</dd>
                        </dl>
                    )}

                    <dl css={itemListItemStyle}>
                        <dt>送料</dt>
                        <dd>0円</dd>
                    </dl>

                    <dl css={itemListItemStyle}>
                        <dt css={{fontWeight: 'bold'}}>合計</dt>
                        <dd css={itemListTotalPriceStyle}><span>{priceUtils.format(validConfirmationResult.totalAmount)}</span>円</dd>
                    </dl>
                </div>

                <p css={confirmTextTitleStyle}>今回ご注文の内容について</p>
                <p css={leadTextStyle}>一ヶ月ごとに届く定期プランです。/自動更新</p>
                <RecurringPlan amountPrice={validConfirmationResult.totalAmount} plan={validConfirmationResult.plan} orderName={orderName} isOrder={isOrder} />

                <p css={confirmTextTitleStyle}>入力内容のご確認</p>
                <p css={leadTextStyle}>入力内容に誤りがないか、必ずご確認ください</p>
                <ConfirmBlackTitle>アカウント情報</ConfirmBlackTitle>
                <ul css={[ulTagStyle, listInfoStyle]}>
                    <li>
                        <p css={[pTagStyle, titleStyle]}>メールアドレス</p>
                        <div css={contentStyle}>
                            {email}
                        </div>
                    </li>
                    <li>
                        <p css={[pTagStyle, titleStyle]}>パスワード</p>
                        <p css={contentStyle}>********</p>
                    </li>
                </ul>
                <p css={confirmAttentionTextStyle}>※アプリにログインする際に必要になりますので、登録内容は忘れないようにしてください</p>
                <ConfirmBlackTitle>ご注文者情報</ConfirmBlackTitle>
                <ul css={[ulTagStyle, listInfoStyle]}>
                    <li>
                        <p css={[pTagStyle, titleStyle]} >お名前</p>
                        <p css={[pTagStyle, contentStyle]} >
                            {billAddress.lastName}
                            {' '}
                            {billAddress.firstName}
                        </p>
                    </li>
                    <li>
                        <p css={[pTagStyle, titleStyle]}>住所</p>
                        <p css={[pTagStyle, contentStyle]}>
                            {getPrefectureName(billAddress.prefectureId)}
                            {billAddress.address1}
                            {billAddress.address2}
                        </p>
                    </li>
                    <li>
                        <p css={[pTagStyle, titleStyle]}>電話番号</p>
                        <p css={[pTagStyle, contentStyle]}>{billAddress.phoneNumber}</p>
                    </li>
                   
                </ul>

                <ConfirmBlackTitle>お届け先情報</ConfirmBlackTitle>
                <ul css={[ulTagStyle, listInfoStyle]}>
                    <li>
                        <p css={[pTagStyle, titleStyle]} >お名前</p>
                        <p css={[pTagStyle, contentStyle]} >
                            {shipAddress.lastName}
                            {' '}
                            {shipAddress.firstName}</p>
                    </li>
                    <li>
                        <p css={[pTagStyle, titleStyle]} >住所</p>
                        <p css={[pTagStyle, contentStyle]} >
                            {getPrefectureName(shipAddress.prefectureId)}
                            {shipAddress.address1}
                            {shipAddress.address2}
                        </p>
                    </li>
                    <li>
                        <p css={[pTagStyle, titleStyle]} >電話番号</p>
                        <p css={[pTagStyle, contentStyle]}>{shipAddress.phoneNumber}</p>
                    </li>
                </ul>

                <ConfirmBlackTitle>お支払い方法</ConfirmBlackTitle>
                <p css={confirmTextStyle}>クレジットカード払い(一括)</p>
                <ul css={[ulTagStyle, listInfoStyle]} >
                    <li>
                        <p css={[pTagStyle, titleStyle]} >カード番号</p>
                        <p css={[pTagStyle, contentStyle]} >************{creditCard.last4}</p>
                    </li>
                    <li>
                        <p css={[pTagStyle, titleStyle]} >有効期限</p>
                        <p css={[pTagStyle, contentStyle]}>{creditCard.exp_month}/{creditCard.exp_year}</p>
                    </li>
                </ul>
                <p css={confirmTextStyle}>
                    支払時期：初回はご注文の翌月1回引き落とし、2回目以降は商品お届けの翌月1回引き落とし(詳細はクレジットカード会員規約をご確認ください)<br />※原則8日以内まで返品可（送料お客様負担）
                </p>

                {validConfirmationResult.startupSupportCode && (
                    <> <ConfirmBlackTitle>スタートアップ支援コード</ConfirmBlackTitle>
                        <ul css={[ulTagStyle, listInfoStyle]} >
                            <li>
                                <p css={[pTagStyle, titleStyle]} >スタートアップ<br />支援コード</p>
                                <p css={[pTagStyle, contentStyle]} >{validConfirmationResult.startupSupportCode}</p>
                            </li>
                        </ul>
                    </>
                )}

                <div>
                    <HoverButton label="注文を確定する" onClick={onClickCheckout} loading={isLoading} isDoubleSendPrevention />
                    <HoverButton label="入力内容を修正する" styleMode="gray" onClick={close} />
                </div>
            </div>
        </Modal>
    )
}
