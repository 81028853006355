import {UserAddress} from '@/network/api/reservationConfirmation'
import {ApiInfo, apiInfoFunc} from '@/network/apiInfo'
import {NoContentResult} from '@/network/fetcher'


export type ReservationCheckout = {
    reservation: {
        /** ユーザのIDトークン */
        idToken:string
        /** クレジットカードのトークン */
        cardToken:string

        itemCode: string
        couponCode?: string
        isSubscribeMailmagazine: boolean,
        shipAddress: UserAddress,
        billAddress: UserAddress

        planName: 'light' | 'standard' |'premium'
        mzdaoCode?: string
    }
}

const apiInfoPostReservationCheckout: ApiInfo<NoContentResult, undefined, ReservationCheckout> = {
    method: 'POST',
    url: () => '/web_ec_v2/tf/reservation/checkout',
    response: {} as NoContentResult,
    query: undefined,
    requestBody: {} as ReservationCheckout
}

export const usePostReservationCheckout = apiInfoFunc.toUseRequest(apiInfoPostReservationCheckout)
