import React from 'react'
import {css} from '@emotion/react'
import {OverLap} from '@components/atoms/Layout/OverLap'
import {ReactComponent as ArrowSvg} from '@assets/img/ico_arrow.svg'


const selectContainerStyle = css({
    width: '100%',
})
const overlapStyle = css({
    right: '16px'
})

/**
 * プルダウンの三角を表示させる。
 * @param props
 * @constructor
 */
export const SelectSuffixIcon: React.FC<{
    children: React.ReactNode
}> = (props) => {
    const { children } = props

    return (
        <OverLap
            overlap={<ArrowSvg width='14px' height='14px'/>}
            containerCss={selectContainerStyle}
            overlapCss={overlapStyle}
        >
            { children }
        </OverLap>)
}
