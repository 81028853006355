import { UserAddress } from '@/network/api/reservationConfirmation'
import { NoContentResult } from '../fetcher'
import { ApiInfo, apiInfoFunc } from '../apiInfo'

export type OrderCheckout = {
    checkout: {
        /** ユーザのIDトークン */
        idToken:string
        /** クレジットカードのトークン */
        cardToken:string

        itemCode: string
        couponCode?: string
        isSubscribeMailmagazine: boolean,
        shipAddress: UserAddress,
        billAddress: UserAddress
        planName: 'light' | 'standard' |'premium'
        mzdaoCode?: string
    }
}


const apiInfoPostOrderCheckout: ApiInfo<NoContentResult, undefined, OrderCheckout> = {
    method: 'POST',
    url: () => '/web_ec_v2/tf/checkout',
    response: {} as NoContentResult,
    query: undefined,
    requestBody: {} as OrderCheckout
}


export const usePostOrderCheckout = apiInfoFunc.toUseRequest(apiInfoPostOrderCheckout)