/**
 * 値段にカンマを挿入する
 */
const format = (num: number | null): string => {
    if (!num) return '0'

    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
export const priceUtils = {
    format,
}
