
import { ReactComponent as TopItemImg } from '@assets/img/img_top_modal.svg'
import { Plan } from '@components/pages/PurchaseFormPage/type'
import { css } from '@emotion/react'
import { priceUtils } from '@/utils/price'
import { pTagStyle } from '@/styles/global'

const createPrice = (plan: Plan) => {
    const {price} = plan
    return `${priceUtils.format(price)}円前後`
}

const container = css({
    width: 'auto',
    padding: '16px',
    marginBottom: '20px',
    background: '#f4f4f4'
})

const itemsClomnStyle = css ({
    margin: '10px 0 24px 0',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
})

const itemsColumnImg = css ({
    width: '80px',
    height: '80px',
    objectFit: 'contain',
    background: '#fff',
})

const itemsClomnTextStyle = css ({
    width: 'calc(100% - 90px)',
    fontSize: '14px',
})
const itemsNoteStyle =({
    margin: '0 0 8px 0',
    fontSize: '13px',
    color: '#666'
})

const noteContract = css({
    borderTop: '1px solid #c2c2c2',
    fontSize: '12px',

    dt: {
        margin: '16px 0 0',
        fontWeight: 'bold',
    },
    dd:{
        margin: '0',
    }
})

const pTitleStyle = css({
    margin: '0 0 8px 0',
    padding: '0 0 0 0.6em',
    color: '#333',
    borderLeft: '4px solid #333',
    fontWeight: '600',
    lineHeight: 1,
})



export const RecurringPlan: React.FC<{
    amountPrice: number
    plan: Plan
    isOrder: boolean
    orderName: string
}> =({amountPrice, plan, isOrder, orderName}) => <div css={container}>
    <p css={pTitleStyle}>初回</p>
    <div css={itemsClomnStyle}>
        <TopItemImg css={itemsColumnImg} />
        <div css={itemsClomnTextStyle}>
            <p css={pTagStyle}>{orderName}</p>
            <p css={pTagStyle}><span css={css({fontWeight: 'bold',})}>{priceUtils.format(amountPrice)}円</span>(税込、送料込)</p>
        </div>
    </div>
    
    <p css={pTitleStyle}>2回目以降</p>

    <div css={itemsClomnStyle}>
        <TopItemImg css={itemsColumnImg} />
        <div css={itemsClomnTextStyle}>
            <p css={pTagStyle}>{plan.tagLine} / {plan.servingQuantity}食</p>
            <p css={pTagStyle}>ご提案時の合計金額<br /><span css={css({fontWeight: 'bold',})}>{createPrice(plan)}</span></p>
        </div>
    </div>
    <p css={itemsNoteStyle}>※提案された商品は、すべて変更可能です。<br/>※購入時の最低利用金額はありません。</p>

    <div css={noteContract}>
        <dl>
            <dt> {isOrder ? '購入' : '予約'}商品のキャンセルについて</dt>
            <dd>セットの購入をキャンセルする場合は、以下のアドレスまでお問い合わせください。<br />support@typefood.jp</dd>
            <dt>定期プランの解約/休止/スキップについて</dt>
            <dd>定期プランへの入会は商品発送後になります。<br />定期プランの解約/休止/スキップはアプリ*から商品発送の3日前までにお願いいたします。</dd>
        </dl>
    </div>
</div>